// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

import 'bootstrap';

import "./bootstrap";

import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'owl.carousel';

import Vue from 'vue';
import './vue/Calendar.js';
import './vue/Booking.js';
import './vue/BookingModal.js';
import './vue/Modal.js';


window.EventManager = new class {
    constructor() {
        this.vue = new Vue();
    }

    fire(event, data = null) {
        this.vue.$emit(event, data);
    }

    listen(event, callback) {
        this.vue.$on(event, callback);
    }
};

Vue.options.delimiters = ['[[', ']]'];
Vue.config.ignoredElements = ['x-trans'];

document.addEventListener('turbo:load', () => loadVueApp());

function loadVueApp() {
    const vueApp = new Vue({
        el: '#app',
        data: {
            showModal: false,
            dynamicComponents: []
        },
        created() {
        },
        mounted() {
            $('.owl-walkthrough').owlCarousel({
                loop:true,
                autoplay:false,
                nav:false,
                items:1
            })
            $('.owl-destinations').owlCarousel({
                loop:true,
                autoplay:true,
                autoplayTimeout:5000,
                autoplayHoverPause:true,
                margin:20,
                nav:false,
                responsive:{
                    0:{
                        items:2
                    },
                    600:{
                        items:3
                    },
                    1000:{
                        items:5
                    }
                }
            })
            $('.owl-cuisine').owlCarousel({
                loop:true,
                autoplay:true,
                autoplayTimeout:5000,
                autoplayHoverPause:true,
                margin:20,
                nav:false,
                responsive:{
                    0:{
                        items:2
                    },
                    600:{
                        items:4
                    },
                    1000:{
                        items:4
                    },
                    1200:{
                        items:3
                    }
                }
            })
            $('.owl-islands').owlCarousel({
                loop:true,
                autoplay:false,
                nav:false,
                items:1
            })
        },
        beforeUnmount() {
        },
        methods: {}
    });
}

Vue.directive('init', {
    bind: function (el, binding, vnode) {
        vnode.context[binding.arg] = binding.value;
    }
});

window.flash = function (message, level = 'success') {
    window.EventManager.fire('flash', {message, level});
};

let elements = document.querySelectorAll('input, select, textarea');

elements.forEach(element => {
    element.addEventListener('invalid', function () {
        element.scrollIntoView(false);
    });
});

$(function () {
    $("form").submit(function (event) {
        $(this).find('button[type="submit"]').prop("disabled", true);
    })
});


window.addEventListener('scroll', function () {
    var sticky = document.querySelector('header');
    var scroll = window.scrollY;

    if (scroll >= 100) {
        sticky.classList.add('fixed');
    } else {
        sticky.classList.remove('fixed');
    }

    fixHeaderStyle();
});

function fixHeaderStyle() {
    // Вашата функция за допълнителен стил на header-а
}

document.addEventListener('DOMContentLoaded', () => {
    let megaMenuLink = document.querySelector('.link-sub-menu');
    let megaMenu = document.querySelector('.sub-menu');

    if (megaMenuLink && megaMenu) {
        let menuItems = document.querySelectorAll('.link-sub-menu');
        menuItems.forEach(element => {
            element.addEventListener('mouseenter', () => {
                element.nextElementSibling.classList.remove('hovered');
            });
        });
    }

    if (megaMenu) {
        let megaMenuItems = document.querySelectorAll('.sub-menu');

        megaMenuItems.forEach(element => {
            element.addEventListener('mouseleave', () => {
                setTimeout(() => {
                    element.classList.add('hovered');
                }, 5);
            });
        });

        megaMenuItems.forEach(element => {
            element.addEventListener('mouseenter', () => {
                setTimeout(() => {
                    element.classList.remove('hovered');
                }, 5);
            });
        });
    }
});